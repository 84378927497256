import classNames from "classnames";

import classes from "./Details.module.pcss";
import {capitalize, classPrefix, createStylesSelector} from "~/lib";
import {DetailsProps} from "~/@types/components/common/DetailsProps";
import Span from "~/components/common/Span";
import {Fragment} from "react";

export default function Details({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    separator = null,
    details
}: DetailsProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    return <Span Component="dl" className={classNames(classPrefix("details"), propsClassName, styles("details"))}>
        {details.map(({field, content, title}, k) => <Fragment key={field}><div className={classNames(styles("row", `row-${field}`))}>
            <dt>{title || capitalize(field)}:</dt>
            <dd>{content}</dd>
        </div>{k < details.length - 1 ? separator: null}</Fragment>)}
    </Span>;
}
