import classNames from "classnames";
import Span from "~/components/common/Span";

import {classPrefix, createStylesSelector} from "~/lib";

import classes from "./Block.module.pcss";
import {BlockProps} from "~/@types/components/common/BlockProps";
import Details from "~/components/common/Details";

export default function Block({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    header: propsHeader,
    controls,
    meta: propsMeta,
    children,
    content: propsContent,
    footer = null,
    HeaderComponent = "h2",
    ContentComponent = "div",
    FooterComponent = "div",
    headerClassName,
    metaClassName,
    contentClassName,
    footerClassName,
    noHeaderWrap = false,
    noContentWrap = false,
    noFooterWrap = false
}: BlockProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const header = <>
        {propsHeader}
        {controls && controls.length ? <ul className={"" + styles("controls")}>
            {controls.map((control, k) => <li key={k}>{control}</li>)}
        </ul> : null}
    </>;

    const meta = typeof propsMeta !== "undefined" ? propsMeta : null;

    const content = typeof propsContent !== "undefined" ? propsContent : children;

    return <div
        className={classNames(classPrefix("block"), propsClassName, styles("block"))}
    >

        {noHeaderWrap ? header : <Span Component={HeaderComponent} className={classNames(headerClassName, styles("header"))}>
            {header}
        </Span>}
        {meta ? <Details details={meta} className={metaClassName} styles={styles}/> : null}
        {noContentWrap ? content : <Span Component={ContentComponent} className={classNames(contentClassName, styles("content"))}>
            {content}
        </Span>}
        {footer ? (noFooterWrap ? footer : <Span Component={FooterComponent} className={classNames(footerClassName, styles("footer"))}>
            {footer}
        </Span>) : null}
    </div>;
}