import classNames from "classnames";

import classes from "./ErrorMessage.module.pcss";
import {classPrefix, createStylesSelector, isDev} from "~/lib";
import {ErrorMessageProps} from "~/@types/components/common/ErrorMessageProps";
import {DetailsProps} from "~/@types/components/common/DetailsProps";
import Details from "~/components/common/Details";
import Span from "~/components/common/Span";

export default function ErrorMessage({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    error,
    children
}: ErrorMessageProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    if (error) {
        if (typeof error === "string") {
            children = <Span className={styles("message")}>{error}</Span>;
        } else if (typeof error === "object") {
            const errDetails: DetailsProps["details"] = [];
            if ("status" in error) {
                errDetails.push({
                    field: "status",
                    content: error.status + ""
                });
            }
            if (isDev() && "data" in error) {
                errDetails.push({
                    field: "data",
                    content: typeof error.data === "object" ? JSON.stringify(error.data) : error.data + ""
                });
            }
            if ("error" in error) {
                errDetails.push({
                    field: "error",
                    content: error.error + ""
                });
            }
            if ("message" in error) {
                errDetails.push({
                    field: "message",
                    content: error.message + ""
                });
            }
            children = <Details
                className={styles("details")}
                styles={styles}
                details={errDetails}
                separator=", "
            />;
        }
    }
    return <div className={classNames(classPrefix("error-message"), propsClassName, styles("error-message"))}>{children}</div>;
}
