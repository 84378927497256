import classNames from "classnames";

import {Tabs as AntdTabs} from "antd";

import classes from "./Tabs.module.css";
import {classPrefix, createStylesSelector} from "~/lib";
import {TabsProps} from "~/@types/components/common/TabsProps";

export default function Tabs({
    items,
    defaultActiveKey,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: TabsProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    if (items) {
        items = items.map((item, k) => {
            if (typeof item.key === "undefined") {
                item.key = `tab-${k}`;
            }
            if (typeof defaultActiveKey === "undefined") {
                defaultActiveKey = item.key;
            }

            return item;
        });
    }

    return <AntdTabs
        {...props}
        defaultActiveKey={defaultActiveKey}
        items={items}
        className={classNames(classPrefix("tabs"), propsClassName, styles("tabs"))}
    />;
}