import classNames from "classnames";

import Span from "~/components/common/Span";

import {Button as AntdButton} from "antd";

import classes from "./Button.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import {ButtonProps} from "~/@types/components/common/ButtonProps";
import {ButtonProps as AntdButtonProps} from "antd/es/button/button";

function Button({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    bold,
    flat,
    type = "button",
    ant = "default",
    ...props
}: ButtonProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const antButtonProps: Omit<AntdButtonProps, "styles"> = {
        type: ant,
        htmlType: type,
    };

    return <Span
        {...props}
        {...antButtonProps}
        styles={styles}
        className={classNames(classPrefix("button"), propsClassName, styles("button", bold ? "bold" : false, flat ? "flat" : false))}
        Component={AntdButton}
    />;
}

export default Button;