import classNames from "classnames";
import {ReactNode, useEffect, useState} from "react";
import {ErrorBoundary as ReactErrorBoundary, FallbackProps} from "react-error-boundary";
import StackTrace, {StackFrame} from "stacktrace-js";
import Button from "~/components/common/Button";
import Span from "~/components/common/Span";
import Block from "~/components/common/Block";
import {createStylesSelector} from "~/lib";
import classes from "./ErrorBoundary.module.pcss";
import {ErrorBoundaryProps, ErrorStackRow} from "~/@types/components/common/ErrorBoundaryProps";
import {ClassesName} from "~/@types";

function parseStack(stack: string): ErrorStackRow[] {
    const stackRows = stack.split("\n");

    return stackRows.reduce(
        (frames, row: string): ErrorStackRow[] => {
            const matches = row.match(/^(.+)@(.+?):(\d+):(\d+)$/);
            if (matches) {
                frames.push({
                    columnNumber: parseInt(matches[4]),
                    fileName: matches[2],
                    functionName: matches[1],
                    lineNumber: parseInt(matches[3]),
                });
            }
            return frames;
        },
        [] as ErrorStackRow[]
    );
}

function getStackRowFrame(stack: StackFrame[]): ErrorStackRow[] {
    return stack.map((stackFrame: StackFrame): ErrorStackRow => ({
        columnNumber: stackFrame.columnNumber,
        fileName: stackFrame.fileName,
        functionName: stackFrame.functionName,
        lineNumber: stackFrame.lineNumber,
    }));
}

function ErrorBoundaryFallback(props: FallbackProps) {
    const {error: propsError, resetErrorBoundary} = props;
    const error = typeof propsError === "object" ? propsError : new Error(propsError);
    const [errorStack, setErrorStack] = useState(parseStack(error.stack));
    const styles = createStylesSelector([classes]);
    useEffect(() => {
        StackTrace.fromError(error)
            .then(stack => setErrorStack(getStackRowFrame(stack)));
    }, [props.error, setErrorStack]);

    const StackFileLine = ({line, className}: {line: ErrorStackRow, className?: ClassesName}) => <div className={classNames(className, styles("file"))}>
        <dt>File:</dt>
        <dd>
            <Span className={"" + styles("file-name")}>{line.fileName}</Span>
            {":"}
            <Span className={"" + styles("line-number")}>{line.lineNumber}</Span>
            {":"}
            <Span className={"" + styles("column-number")}>{line.columnNumber}</Span>
        </dd>
    </div>;

    const controls: ReactNode[] = [];
    if (resetErrorBoundary) {
        const buttonProps = {onClick: resetErrorBoundary};
        controls.push(<Button
            {...buttonProps}
            ico={{
                ico: "Reload",
                mode: "outlined"
            }}
            icoPosition="only"
            type="button"
        >
            Try to reset error
        </Button>);
    }

    return <Block
        styles={styles}
        className={"" + styles("error-boundary-message")}
        header="Error occurred during component rendering"
        headerClassName={"" + styles("header")}
        controls={controls}
    >
        <p className={"" + styles("error-description")}>{error.message}</p>
        {import.meta.env.MODE === "development" ? <dl className={"" + styles("error-stack")}>
            <StackFileLine className={"" + styles("row")} line={error}/>
            <div className={classNames(styles("row", "stack"))}>
                <dt>Stack</dt>
                <dd><ul>
                    {errorStack.map((line, i) => <li key={i}>
                        <dl>
                            <div className={"" + styles("function")}>
                                <dt>Function:</dt>
                                <dd>{line.functionName}</dd>
                            </div>
                            <StackFileLine line={line}/>
                        </dl>
                    </li>)}
                </ul></dd>
            </div>
        </dl> :
            null}
    </Block>;
}

export default function ErrorBoundary({children, onReset}: ErrorBoundaryProps) {
    return <ReactErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={onReset}
    >
        {children}
    </ReactErrorBoundary>;
}