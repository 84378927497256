import classNames from "classnames";

import ReactMarkdown, {defaultUrlTransform} from "react-markdown";

import classes from "./Markdown.module.pcss";
import {classPrefix, createStylesSelector, reformatCode, sanitizePath} from "~/lib";
import {MarkdownProps} from "~/@types/components/common/MarkdownProps";
import Link from "~/components/common/Link";
import {LinkProps} from "~/@types/components/common/LinkProps";
import {ReactNode, useContext} from "react";
import {MarkdownUrlTransformContextProps} from "~/@types/context";
import MarkdownUrlTransformContext from "~/context/MarkdownUrlTransformContext";
import {JSX} from "react/jsx-runtime";
import Code from "~/components/common/Code";
import {APP_ALLOWED_CODE_LANGUAGES, APP_ALLOWED_CODE_PARSE_LANGUAGES} from "~/constants/App";
import {CodeLanguages, CodeParseLanguages} from "~/@types";
import {CodeProps} from "~/@types/components/common/CodeProps";
import IntrinsicElements = JSX.IntrinsicElements;

export default function Markdown({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    ...props
}: MarkdownProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const {
        urlTransform: markdownURLTransform,
        useDefaultTransform: markdownUseDefaultTransform,
    } = useContext(MarkdownUrlTransformContext) as MarkdownUrlTransformContextProps;

    const urlTransform = (url: string): string => {
        if (typeof markdownURLTransform === "function") {
            url = markdownURLTransform(url);
        }
        return markdownUseDefaultTransform ? defaultUrlTransform(url) : url;
    };

    const components = {
        a: (props: IntrinsicElements["a"]) => {
            let href = props.href + "";
            const linkProps: LinkProps = {children: props.children};

            if (href.startsWith("slug:")) {
                href = href.substring(5);
                const [, slug, del, path] = href.match(/(.+)([?#/])(.+)/) || ["", href];
                linkProps.to = path ? sanitizePath(del + path) : "";
                linkProps.slug = slug;
            } else {
                linkProps.to = href;
                linkProps.native = true;
            }

            return <Link {...linkProps}/>;
        },
        pre: ({children}: IntrinsicElements["code"]) => {
            let ret: ReactNode = null;
            if (children && typeof children === "object" && "props" in children) {
                const {className} = children.props;
                let {children: codeChildren} = children.props;
                const codeProps: CodeProps = {
                    language: "shell",
                    name: "markdown-code",
                    readOnly: true,
                    editable: false,
                    setup: {
                        lineNumbers: false,
                        tabSize: 4,
                    }
                };
                const [, codeLangAttr] = className && className.match(/^language-([\w,:]+)$/) || [];
                if (codeLangAttr) {
                    const [codeLang, ...attrs] = codeLangAttr.split(",");
                    if (APP_ALLOWED_CODE_LANGUAGES.includes(codeLang as CodeLanguages)) {
                        codeProps.language = codeLang as CodeLanguages;
                    }
                    let pretty = false;
                    attrs.forEach(attr => {
                        if (attr.startsWith("indent:")) {
                            const tabSize = parseInt(attr.substring(7));
                            if (!codeProps.setup) {
                                codeProps.setup = {};
                            }
                            codeProps.setup.tabSize = tabSize;
                        }
                        if (attr === "pretty" && APP_ALLOWED_CODE_PARSE_LANGUAGES.includes(codeLang as CodeParseLanguages)) {
                            pretty = true;
                        }
                        if (attr === "lineNumbers") {
                            if (!codeProps.setup) {
                                codeProps.setup = {};
                            }
                            codeProps.setup.lineNumbers = true;
                        }
                    });
                    if (pretty) {
                        codeChildren = reformatCode(
                            "" + codeChildren,
                            codeLang as CodeParseLanguages,
                            " ".repeat(codeProps?.setup?.tabSize || 4)
                        );
                    }

                }
                ret = <Code {...codeProps}>{codeChildren}</Code>;
            }

            return ret;
        },
        p: (props: IntrinsicElements["p"]) => <div className={classNames(props.className, styles("paragraph"))}>{props.children}</div>,
    };

    return <ReactMarkdown
        {...props}
        className={classNames(classPrefix("markdown"), propsClassName, styles("markdown"))}
        urlTransform={urlTransform}
        components={components}
    />;
}