import classNames from "classnames";
import {Form as ReactFinalForm} from "react-final-form";

import FormContext from "~/context/FormContext";

import classes from "./Form.module.pcss";
import {classPrefix, createStylesSelector, getFormFieldContextProps, isPromise} from "~/lib";
import {FormProps} from "~/@types/components/common/FormProps";
import {SubmissionErrors} from "final-form";

export default function Form<FormValues>({
    children,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: FormProps<FormValues>) {
    const {
        name,
        onChange,
        onSubmit
    } = getFormFieldContextProps(props);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    return <ReactFinalForm<FormValues>
        {...props}
        onSubmit={(values, form) => {
            let ret;
            if (onSubmit) {
                const submitRet = onSubmit(values, form);
                if (submitRet) {
                    if (submitRet && typeof submitRet === "object" && isPromise(submitRet)) {
                        ret = Promise.resolve(submitRet);
                    } else if (submitRet && typeof submitRet === "object") {
                        ret = submitRet as SubmissionErrors;
                    }
                }
            }

            return ret;
        }}
        className={classNames(classPrefix("form"), propsClassName, styles("form"))}
    >
        {({handleSubmit, form, ...props}) => <FormContext.Provider value={{name, onChange}}>
            <form name={name} onSubmit={handleSubmit}>
                {children({
                    form,
                    handleSubmit,
                    ...props
                })}
            </form>
        </FormContext.Provider>}
    </ReactFinalForm>;
}