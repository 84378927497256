import classNames from "classnames";
import {Field as ReactFinalFormField, FieldInputProps as ReactFinalFormFieldInputProps} from "react-final-form";

import FieldContext from "~/context/FieldContext";

import classes from "./Field.module.pcss";
import {
    capitalize,
    classPrefix,
    createEventHandler,
    createStylesSelector,
    getFormFieldContextProps,
    wrapTargetEvent
} from "~/lib";
import {FieldProps} from "~/@types/components/common/FieldProps";
import {EventHandler} from "~/@types";
import {useCallback} from "react";

export default function Field<FieldValue = any>({
    children,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: FieldProps<FieldValue>) {
    const {
        name,
        ...contextProps
    } = getFormFieldContextProps(props);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const createEventProps = (input: ReactFinalFormFieldInputProps<FieldValue, HTMLElement>) => [
        "change",
        "blur",
        "focus",
    ].reduce<Partial<ReactFinalFormFieldInputProps<any>>>(
        (props, eventType) => {
            const eventProp = `on${capitalize(eventType)}`;
            props[eventProp] = useCallback((evt: any) => {
                const handler = createEventHandler(
                    [
                        input[eventProp] as EventHandler,
                        contextProps[eventProp],
                    ],
                    e => wrapTargetEvent(e, "change", {name})
                );
                if (typeof handler === "function") {
                    handler(evt);
                }
            }, [input.name]);
            return props;
        },
        {}
    );

    return <ReactFinalFormField<FieldValue>
        {...props}
        name={props.name}
        onChange={contextProps.onChange}
        className={classNames(classPrefix("field"), propsClassName, styles("field"))}
    >
        {({input, ...props}) => <FieldContext.Provider value={{
            name,
            onChange: contextProps.onChange
        }}>
            {children({
                input: {
                    ...input,
                    name,
                    ...createEventProps(input),
                },
                ...props,
                fieldName: name,
                name
            })}
        </FieldContext.Provider>}
    </ReactFinalFormField>;
}