import classNames from "classnames";

import classes from "./Date.module.pcss";
import {classPrefix, createStylesSelector, parseDateString} from "~/lib";
import {DateFormat, DateProps} from "~/@types/components/common/DateProps";
import Span from "~/components/common/Span";

export const dateFormats: {[key: string]: DateFormat} = {
    default: {
        locale: undefined,
        options: {}
    },
};

export default function DateTime({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    format = "default",
    locale: propsLocale = undefined,
    options: propsOptions = undefined,
    extendOptions = false,
    children: dateString
}: DateProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    let formatedString: string = "";

    const dateObject = parseDateString(dateString);
    if (dateObject) {
        const {locale, options} = format ? dateFormats[format] : dateFormats.default;
        formatedString = dateObject.toLocaleString(
            propsLocale ? propsLocale : locale,
            propsOptions ? (extendOptions ? {...options, ...propsOptions} : propsOptions) : options
        );
    } else {
        formatedString = "invalid date";
    }

    return <Span
        Component="span"
        title={dateString + ""}
        className={classNames(classPrefix("markdown"), propsClassName, styles("markdown"))}
    >
        {formatedString}
    </Span>;
}